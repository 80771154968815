const arrowSlider = (
  <svg
    width="24"
    height="38"
    viewBox="0 0 24 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3875 17.7699L1.37579 0.577529C1.31827 0.532247 1.24915 0.504106 1.17636 0.496338C1.10357 0.48857 1.03006 0.50149 0.964285 0.533614C0.898507 0.565738 0.843124 0.615764 0.804496 0.677946C0.765867 0.740128 0.74556 0.811944 0.745907 0.885147V4.65956C0.745907 4.89882 0.858212 5.12831 1.04376 5.2748L18.6218 19.0004L1.04376 32.726C0.853329 32.8725 0.745907 33.1019 0.745907 33.3412V37.1156C0.745907 37.4428 1.12188 37.6234 1.37579 37.4232L23.3875 20.2309C23.5745 20.0849 23.7259 19.8982 23.83 19.685C23.9341 19.4718 23.9882 19.2377 23.9882 19.0004C23.9882 18.7631 23.9341 18.529 23.83 18.3157C23.7259 18.1025 23.5745 17.9158 23.3875 17.7699Z"
      fill="#684ADD"
    />
  </svg>
);
export default arrowSlider;
