// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  margin: 0 auto;
  max-width: 1164px;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterPage/RegisterPage.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;EACA,aAAA;AACF","sourcesContent":[".main {\n  margin: 0 auto;\n  max-width: 1164px;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
