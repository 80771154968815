// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  margin: 0;
}
ul {
  padding: 0;
}
li {
  list-style: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;;;;;;EAQE,SAAS;AACX;AACA;EACE,UAAU;AACZ;AACA;EACE,gBAAgB;AAClB","sourcesContent":["h1,\nh2,\nh3,\nh4,\nh5,\nh6,\nul,\nli {\n  margin: 0;\n}\nul {\n  padding: 0;\n}\nli {\n  list-style: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
