// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.links .line {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/AddLinks/AddLinks.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,aAAA;EACA,8BAAA;AAAJ","sourcesContent":[".links {\n  .line {\n    margin-bottom: 50px;\n    display: flex;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
