const iconDone = (
  <svg
    width="51"
    height="42"
    viewBox="0 0 51 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.88042 17.1483H6.8792H2.90761C1.69283 17.1483 1.01362 18.5482 1.76439 19.5004L1.76472 19.5008L17.3272 39.2167L17.3275 39.2171C18.4567 40.6462 20.6207 40.6413 21.7524 39.2201L21.7551 39.2166L49.4995 4.05891C49.5006 4.05749 49.5018 4.05607 49.5029 4.05465C50.2699 3.09383 49.559 1.70508 48.3622 1.70508H44.3906C43.5302 1.70508 42.7102 2.09914 42.1775 2.78125C42.1769 2.78204 42.1762 2.78282 42.1756 2.78361L19.5384 31.4604L9.09093 18.2227C8.82754 17.8883 8.49182 17.6179 8.10897 17.4318C7.72613 17.2457 7.3061 17.1488 6.88042 17.1483Z"
      fill="#BDEA09"
      stroke="#BDEA09"
      strokeWidth="2"
    />
  </svg>
);
export default iconDone;
