// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommonButton_button__RnIZc {
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  background: none;
  font-size: 16px;
  font-weight: 400;
  color: #282828;
}

.CommonButton_primary__j1-\\+k {
  background: #42dbbf;
}

.CommonButton_outline__WZ06E {
  padding: 10px 24px;
  border: 2px solid #42dbbf;
}`, "",{"version":3,"sources":["webpack://./src/UI/CommonButton/CommonButton.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,cCRkB;ADOpB;;AAGA;EACE,mBCCa;ADDf;;AAEA;EACE,kBAAA;EACA,yBAAA;AACF","sourcesContent":["@import '../../styles/variables';\n\n.button {\n  padding: 12px 24px;\n  border: none;\n  border-radius: 5px;\n  background: none;\n  font-size: 16px;\n  font-weight: 400;\n  color: $primary-dark-text;\n}\n.primary {\n  background: $secondary-bg;\n}\n.outline {\n  padding: 10px 24px;\n  border: 2px solid $secondary-bg;\n}\n","// COLORS\n$primary-dark-text: #282828;\n$primary-dark-title: #1f1f55;\n$primary-dark-bg: #1f1f55;\n\n$primary-light-title: #ffffff;\n$primary-light-text: #ffffff;\n$primary-light-bg: #ffffff;\n\n$secondary-light-title: #d9d9d9;\n$secondary-light-text: #d9d9d9;\n$secondary-dark-title: #684add;\n\n$secondary-bg: #42dbbf;\n$secondary-light-bg: #d9d9d9;\n\n$primary-dark-hover: #28282817;\n$primary-light-hover: #d8d8fe17;\n\n//HEW COLOR\n$main-blue: #1f1f55;\n$main-green: #42dbbf;\n$main-purple: #684add;\n$main-yellow: #bdea09;\n$main-white: #ffffff;\n\n$shaded-mid-blue: #575797;\n$shaded-mid-light-blue: #8181da;\n$shaded-light-blue: #d8d8fe;\n$shaded-mid-light-green: #8df4e2;\n$shaded-light-green: #cefff6;\n\n$text-color-light: #e5e5e6;\n$text-color-middle: #808099;\n$text-color-dark: #282828;\n\n$allert-color-red: #eb5757;\n$stroke-color: #f2994a;\n\n$hover-color-dark: #28282817;\n$hover-color-light: #d8d8fe17;\n\n$box-shadow-color: #00000040;\n\n//SIZES\n$main-border-radius: 6px;\n\n// ANIMATION\n$main-transition: ease-in-out 200ms;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `CommonButton_button__RnIZc`,
	"primary": `CommonButton_primary__j1-+k`,
	"outline": `CommonButton_outline__WZ06E`
};
export default ___CSS_LOADER_EXPORT___;
