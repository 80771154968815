// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecomendationCard {
  margin-bottom: 40px;
  padding: 20px;
  width: 420px;
  border-radius: 6px;
  background-color: #42dbbf;
}
.RecomendationCard-Title {
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
}
.RecomendationCard-Description {
  display: flex;
  gap: 10px;
}
.RecomendationCard-Time {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  color: #787878;
}`, "",{"version":3,"sources":["webpack://./src/components/RecomendationCard/Recomendation.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;AACF;AAAE;EACE,oBAAA;EACA,eAAA;EACA,gBAAA;AAEJ;AAAE;EACE,aAAA;EACA,SAAA;AAEJ;AAAE;EACE,aAAA;EACA,yBAAA;EACA,SAAA;EACA,cAAA;AAEJ","sourcesContent":[".RecomendationCard {\n  margin-bottom: 40px;\n  padding: 20px;\n  width: 420px;\n  border-radius: 6px;\n  background-color: #42dbbf;\n  &-Title {\n    padding-bottom: 20px;\n    font-size: 24px;\n    font-weight: 500;\n  }\n  &-Description {\n    display: flex;\n    gap: 10px;\n  }\n  &-Time {\n    display: flex;\n    justify-content: flex-end;\n    gap: 30px;\n    color: #787878;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
