/* eslint-disable */
export const linkedinIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.868 0.625H2.13203C1.3 0.625 0.625 1.3 0.625 2.13203V17.868C0.625 18.7 1.3 19.375 2.13203 19.375H17.868C18.7 19.375 19.375 18.7 19.375 17.868V2.13203C19.375 1.3 18.7 0.625 17.868 0.625ZM17.868 17.875C7.37266 17.8727 2.125 17.8703 2.125 17.868C2.12734 7.37266 2.12969 2.125 2.13203 2.125C12.6273 2.12734 17.875 2.12969 17.875 2.13203C17.8727 12.6273 17.8703 17.875 17.868 17.875ZM3.40469 7.65391H6.18672V16.6023H3.40469V7.65391ZM4.79688 6.43047C5.68516 6.43047 6.40937 5.70859 6.40937 4.81797C6.40937 4.60621 6.36767 4.39653 6.28663 4.20089C6.2056 4.00525 6.08682 3.82749 5.93709 3.67776C5.78735 3.52802 5.60959 3.40925 5.41395 3.32821C5.21831 3.24718 5.00863 3.20547 4.79688 3.20547C4.58512 3.20547 4.37544 3.24718 4.1798 3.32821C3.98416 3.40925 3.8064 3.52802 3.65667 3.67776C3.50693 3.82749 3.38815 4.00525 3.30712 4.20089C3.22608 4.39653 3.18437 4.60621 3.18437 4.81797C3.18203 5.70859 3.90391 6.43047 4.79688 6.43047ZM10.7102 12.175C10.7102 11.0078 10.9328 9.87812 12.3789 9.87812C13.8039 9.87812 13.825 11.2117 13.825 12.25V16.6023H16.6047V11.6945C16.6047 9.28516 16.0844 7.43125 13.2695 7.43125C11.9172 7.43125 11.0102 8.17422 10.6375 8.87734H10.6V7.65391H7.93047V16.6023H10.7102V12.175Z"
      fill="black"
    />
  </svg>
);
